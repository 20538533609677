.flip-card {
  perspective: 1000px;
  width: 180px;
  height: 180px;
  cursor: pointer;
}

.flip-card-inner {
  transform-style: preserve-3d;
  transition: transform 0.5s;
  position: relative;
  width: 100%;
  height: 100%;
}

.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-card-front {
  background-color: transparent;
}

.flip-card-back {
  background-color: transparent;
  transform: rotateY(180deg);
}
