.logo {
  height: 72px;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  margin-top: 0.5rem;
  z-index: 10;
}

.closer {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  z-index: 10;

  & > div:nth-child(1) {
    transition: all 100ms ease-in-out;
    transform: rotate(-45deg) translate(-10px, 5px);
    background-color: #ffffff;
  }
  & > div:nth-child(2) {
    transition: all 100ms ease-in-out;
    opacity: 0;
  }
  & > div:nth-child(3) {
    transition: all 100ms ease-in-out;
    transform: rotate(45deg) translate(-10px, -5px);
    background-color: #ffffff;
  }
}

.navStrip {
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 0.2rem;
  width: 2rem;
  border-radius: 0.2rem;
  background-color: #1a659e;
  z-index: 10;
}

.navOverlay {
  z-index: 1;
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a659e;
  height: 100vh;
  width: 100vw;
  transition: all 250ms ease-in-out;
  opacity: 1;
}

.navOverlay_closed {
  z-index: -1;
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a659e;
  height: 100vh;
  width: 100vw;
  transition: all 250ms ease-in-out;
  opacity: 0;
}

.navItem:hover {
  text-decoration: underline #1a659e 2.5px solid;
}